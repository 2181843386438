import React, { ComponentProps, CSSProperties } from "react";
import styled from "styled-components";
import Typography from "../../Foundation/Typography/Typography";

export interface DividerProps {
  direction?: "vertical" | "horizontal";
  size?: number;
  weight?: number;
  mt?: CSSProperties["marginTop"];
  mb?: CSSProperties["marginBottom"];
  text?: string;
  color?: string;
  opacity?: CSSProperties["opacity"];
}

const DividerStyled = styled("div")<DividerProps>(
  ({
    direction = "vertical",
    size,
    weight = 2,
    mt,
    mb,
    color = "#E8E8E8",
    opacity,
    text,
  }) => {
    const length = size ? size : "100%";
    const fat = weight;
    const width = direction === "vertical" ? fat : length;
    const height = direction === "vertical" ? length : fat;
    const heightWithMargin =
      // @ts-expect-error
      height > 0 ? height + (mt || 0) + (mb || 0) : height;
    return {
      display: "flex",
      flexDirection: direction === "vertical" ? "column" : "row",
      [`&:before${!!text && ",&:after"}`]: {
        content: '""',
        display: "block",
        width,
        height,
        marginBottom: mb,
        marginTop: mt,
        backgroundColor: color,
      },
      width,
      heightWithMargin,
      position: "relative",
      opacity,
    };
  }
);

const Divider = (props: DividerProps) => {
  const { ...rest } = props;
  const { text, color } = props;
  return (
    <DividerStyled {...rest}>
      {text && (
        <Typography
          style={{
            padding: "0 15px",
            ...(color && { color }),
          }}
          variant="body2"
          color={"greyText"}
        >
          {text}
        </Typography>
      )}
    </DividerStyled>
  );
};

export default Divider;
