import React, { useEffect, useRef, useState } from "react";
import useObserver from "../../Hooks/useObserver";
import { $champagne7 } from "../../Utils/color";
import FontIcon from "../FontIcon/FontIcon";
import Typography from "../Typography/Typography";
import { SwitcherRoot, SwitcherButton } from "./Switcher.styles";

export interface ISwitcherProps {
  id?: string;
  option1: {
    label: string | React.ReactNode;
    action?: () => void;
  };
  option2: {
    label: string | React.ReactNode;
    action?: () => void;
  };
  default: 0 | 1;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  disabled?: boolean;
  editable?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  size?: "default" | "extra-large";
  background?: "opaque" | "translucent";
  onChange?: (value: 0 | 1) => void;
  onClickValue?: (value: 0 | 1) => void;
}

const Switcher = ({
  children,
  onChange,
  onClickValue,
  ...props
}: ISwitcherProps) => {
  const {
    option1,
    option2,
    default: defaultSelected = 0,
    disabled = false,
    editable = true,
  } = props;
  const [selectedIndex, setSelectedIndex] = useState(defaultSelected);
  const [transitionCss, setTransitionCss] = useState("none");
  const [firstWidth, setFirstWidth] = useState(0);
  const [secondWidth, setSecondWidth] = useState(0);
  const firstOptionRef = useRef<HTMLButtonElement>(null);
  const secondOptionRef = useRef<HTMLButtonElement>(null);
  const indicatorRef = useRef(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedIndex !== defaultSelected) {
      setSelectedIndex(defaultSelected);
    }
  }, [defaultSelected]);

  useObserver(firstOptionRef, (entries) => {
    const { width } = entries[0].target.getBoundingClientRect();
    setFirstWidth(width);
  });

  useObserver(secondOptionRef, (entries) => {
    const { width } = entries[0].target.getBoundingClientRect();
    setSecondWidth(width);
  });

  const handleChange = (value: 0 | 1) => {
    onChange && onChange(value);
  };

  const selectItem = (index: 0 | 1) => {
    onClickValue && onClickValue(index);
    if (editable) {
      setSelectedIndex(index);
      handleChange(index);
      setTransitionCss("all 0.3s ease");
      const { action } = index === 0 ? option1 : option2;
      action && action();
    }
  };

  const selectFirst = () => selectItem(0);
  const selectSecond = () => selectItem(1);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === "Enter") {
      const target = event.target as HTMLButtonElement;
      if (target.getAttribute("data-id") !== `${selectedIndex}`) {
        if (selectedIndex === 0) {
          selectSecond();
        } else {
          selectFirst();
        }
      }
    }
  };

  return (
    <SwitcherRoot {...props} ref={wrapperRef} selectedIndex={selectedIndex}>
      <SwitcherButton
        ref={firstOptionRef}
        className="caption caption_2"
        selected={selectedIndex === 0}
        data-id={0}
        data-width={firstWidth}
        onClick={selectFirst}
        onKeyDown={handleKeyDown}
        aria-label={`${props.option1.label || ""}`}
        disabled={disabled}
        ready={firstWidth > 0}
      >
        <FontIcon icon="check_mark" size={16} color={$champagne7} />
        <Typography
          as="span"
          variant="button2"
          theme={selectedIndex === 0 ? "light" : "dark"}
        >
          {props.option1.label}
        </Typography>
      </SwitcherButton>
      <SwitcherButton
        ref={secondOptionRef}
        className="caption caption_2"
        selected={selectedIndex === 1}
        data-id={1}
        data-width={secondWidth}
        onClick={selectSecond}
        onKeyDown={handleKeyDown}
        aria-label={`${props.option2.label || ""}`}
        disabled={disabled}
        ready={secondWidth > 0}
      >
        <FontIcon icon="check_mark" size={16} color={$champagne7} />
        <Typography
          as="span"
          variant="button2"
          theme={selectedIndex === 1 ? "light" : "dark"}
        >
          {props.option2.label}
        </Typography>
      </SwitcherButton>
      <span
        className="switcher-indicator"
        ref={indicatorRef}
        style={{
          opacity: disabled ? 0.5 : 1,
          width: selectedIndex === 0 ? firstWidth : secondWidth,
          transform: selectedIndex > 0 ? `translateX(${firstWidth}px)` : "none",
          transition: transitionCss,
        }}
      ></span>
    </SwitcherRoot>
  );
};

export default Switcher;
