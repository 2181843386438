import hasWindow from "@src/utils/hasWindow";

const isTouchDevice = () => {
  return (
    hasWindow() &&
    ("ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      // @ts-ignore
      navigator.msMaxTouchPoints > 0)
  );
};

export default isTouchDevice;
