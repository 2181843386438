import { FC, ReactElement, ReactNode } from "react";
import useLoadAfterInteraction from "./useLoadAfterInteraction";

interface ISuspenseAfterInteractionProps {
  fallback?: ReactNode;
  children: ReactNode;
}

const SuspenseAfterInteraction: FC<ISuspenseAfterInteractionProps> = (
  props
) => {
  const { fallback, children } = props;
  const hasInteraction = useLoadAfterInteraction();
  return (hasInteraction ? children : fallback || null) as ReactElement;
};

export default SuspenseAfterInteraction;
