import { graphql, useStaticQuery } from "gatsby";
import { Language } from "@src/interface/Language";
import { useMemo } from "react";
import usePageLang from "../usePageLang";

export type CategoryItem = Queries.categoryFieldsFragment;

export const useAllCategory = (filter?: {
  lang: Language;
}): { data: Queries.categoryFieldsFragment[] } => {
  const lang = usePageLang();

  // static query
  const { allCategory } = useStaticQuery<Queries.AllCategoryQuery>(graphql`
    query AllCategory {
      allCategory(sort: { position: ASC }) {
        nodes {
          ...categoryFields
        }
      }
    }
  `);

  const data: CategoryItem[] = useMemo(() => {
    return allCategory.nodes.filter((node) => node.lang === lang);
  }, [allCategory, lang]);

  return { data };
};
