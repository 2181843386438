import React from "react";
import Button, { IButtonProps } from "../../Foundation/Button/Button";
import { defaultTheme } from "../../Themes/defaultTheme";
import { hexToRGB } from "../../Utils/font";
import styled from "styled-components";
import { IconName } from "../../lib/svgIcon/components";

export interface ISignInButtonProps extends IButtonProps {
  children: React.ReactNode;
  icon?: IconName;
}

const _SocialNetworkButton = styled(Button)((props) => {
  return {
    height: 52,
    "> span": {
      flex: "1",
      textAlign: "left"
    },
  };
});

const SignInButton = React.forwardRef<HTMLButtonElement, ISignInButtonProps>(
  (props: ISignInButtonProps, ref) => {
    const {
      color: { greyText2: $greyText2, placeholder: $placeholder },
    } = defaultTheme;
    const $transparent = "transparent";
    const { icon, children, loading, disabled, onClick } = props;
    return (
      <_SocialNetworkButton
        align="start"
        type={"button"}
        customize={{
          bg: "#F3F3F3",
          color: $placeholder,
          disabledBg: $transparent,
          border: $transparent,
          hoverColor: $placeholder,
          hoverBg: hexToRGB($greyText2, 0.4),
          hoverBorder: $transparent,
          activeBg: "#F3F3F3",
          activeBorder: $transparent,
          disabledBorder: "#F3F3F3",
        }}
        {...{ ref, icon, children, loading, disabled, onClick }}
      />
    );
  }
);

export default SignInButton;
