import _ from "underscore";
import { app } from "../model/AppModel";
import PhoneNumberFormat from "@src/lib/libphonenumber/PhoneNumberFormat";
import {
  GATSBY_CONF_DEFAULT_COUNTRY_PHONE,
  GATSBY_CONF_PHONE_COUNTRY_SHORT_LIST,
  GATSBY_ENV_COUNTRY_CODE,
} from "@src/utils/constants";
import { useEffect, useState } from "react";

// private instance
type PhoneNumberUtil = libphonenumber.PhoneNumberUtil;
let instance: libphonenumber.PhoneNumberUtil | null = null;
let preloadPromise: Promise<any>;
const //   AsYouTypeFormatter = libPhoneNumber.AsYouTypeFormatter,
  FORMAT_E164 = PhoneNumberFormat.E164,
  // FORMAT_NATIONAL = PhoneNumberFormat.NATIONAL,
  FORMAT_INTERNATIONAL = PhoneNumberFormat.INTERNATIONAL;

const phoneUtils = {
  load: async () => {
    const _instance = await new Promise<PhoneNumberUtil>((resolve, reject) => {
      const ResolveModule = (libPhoneNumber: typeof libphonenumber) => {
        if (!instance) {
          instance = libPhoneNumber.PhoneNumberUtil.getInstance();
        }
        resolve(instance);
      };
      const RejectModule = (err: any) => {
        reject(err);
      };
      if (instance) {
        resolve(instance);
      } else if (!instance && !preloadPromise) {
        console.log("preloadGoogleLibPhoneNumber");
        preloadPromise = import("google-libphonenumber")
          .then(ResolveModule)
          .catch(RejectModule);
      } else if (!instance && preloadPromise) {
        console.log("preloadGoogleLibPhoneNumber preloadPromise");
        preloadPromise.then(ResolveModule).catch(RejectModule);
      } else {
        reject();
      }
    });
    return _instance;
  },
  validate: function (
    phone: any,
    countryCode?: any,
    fail?: (arg0: unknown) => void,
    success?: any
  ) {
    //
    // validate phone number,
    // return false & fail callback on failure
    // return phone formatted E164 on success & success callback
    //
    if (!instance) return false;
    try {
      //
      let defaultCountryCode = countryCode || GATSBY_CONF_DEFAULT_COUNTRY_PHONE,
        phoneParsing = instance.parseAndKeepRawInput(phone, defaultCountryCode),
        callback,
        validation,
        formatted,
        phoneCountryCode = phoneUtils.getRegionCodeForNumber(
          phone,
          countryCode
        ),
        E164 = instance.format(
          phoneParsing,
          FORMAT_E164 as unknown as libphonenumber.PhoneNumberFormat
        );

      validation = instance.isValidNumber(phoneParsing);
      if (!validation) {
        if (phoneCountryCode) {
          phoneParsing = instance.parseAndKeepRawInput(phone, phoneCountryCode);
          validation = instance.isValidNumber(phoneParsing);
        }
      }
      formatted = validation ? E164 : validation;
      callback = validation ? success : fail;

      //
      if (callback && _.isFunction(callback)) {
        callback(formatted);
      }
      return formatted;
    } catch (err) {
      if (fail && _.isFunction(fail)) {
        fail(err);
      }
      return false;
    }
  },
  format: function (phone: any, countryCode: any, format: PhoneNumberFormat) {
    format = format || FORMAT_E164;
    // formate un numéro dans son format national.
    let output = phone;
    countryCode = countryCode || GATSBY_ENV_COUNTRY_CODE;
    if (instance) {
      try {
        const phoneParsing = instance.parseAndKeepRawInput(phone, countryCode);
        output = instance.format(
          phoneParsing,
          format as unknown as libphonenumber.PhoneNumberFormat
        );
        console.warn("PhoneNumberFormat output", output);
      } catch (err) {
        console.warn("PhoneNumberFormat error", err);
      }
    }
    return output;
  },
  // formatNational: function (phone: any, countryCode?: any) {
  //   // formate un numéro dans son format national.
  //   return phoneUtils.format(phone, countryCode, FORMAT_NATIONAL);
  // },
  formatInternational: function (phone: any, countryCode?: any) {
    // formate un numéro dans son format national.
    return phoneUtils.format(
      phone,
      countryCode,
      FORMAT_INTERNATIONAL as PhoneNumberFormat
    );
  },
  // inspectPhoneNumber: function (phone: any, countryCode: any) {
  //   let formatted = phone,
  //     E164 = undefined,
  //     isPossibleNumber = false,
  //     success = false,
  //     phoneParsing,
  //     phoneCountryCode,
  //     output: any = {};
  //   try {
  //     phoneParsing = instance.parseAndKeepRawInput(phone, countryCode);
  //     isPossibleNumber = instance.isPossibleNumber(phoneParsing);
  //     phoneCountryCode = instance.getRegionCodeForNumber(phoneParsing);
  //     if (phoneCountryCode !== countryCode) {
  //       // si le pays ne correspond pas, on ne formate pas.
  //     } else if (isPossibleNumber) {
  //       formatted = instance.format(phoneParsing, FORMAT_NATIONAL); //formatInOriginalFormat(phoneParsing,countryCode);
  //       success = true;
  //     }
  //     E164 = instance.format(phoneParsing, FORMAT_E164); //formatInOriginalFormat(phoneParsing,countryCode);
  //   } catch (err) {
  //     // on error return the original format
  //   }
  //   output.formatted = formatted;
  //   output.E164 = E164;
  //   output.success = success;
  //   output.phoneParsing = phoneParsing;
  //   output.isPossibleNumber = isPossibleNumber;
  //   output.phoneCountryCode = phoneCountryCode;
  //   return output;
  // },
  getRegionCodeForNumber: function (phone: string, countryCode?: string) {
    countryCode = countryCode || GATSBY_CONF_DEFAULT_COUNTRY_PHONE;
    let regionCode;
    if (instance) {
      try {
        const phoneParsing = instance.parseAndKeepRawInput(phone, countryCode);
        regionCode = instance.getRegionCodeForNumber(phoneParsing);
      } catch (err) {
        // on va utiliser le pays par défaut en cas d'erreur
        regionCode = countryCode;
      }
    }
    return regionCode;
  },
  getSupportedRegions: function (): Array<{
    _s: string;
    country: boolean;
    emoji: string;
    shortListed: boolean;
    name: string;
    code: string;
  }> {
    let countryList;
    const regions = instance ? instance.getSupportedRegions() : [],
      countryShortList = GATSBY_CONF_PHONE_COUNTRY_SHORT_LIST.split(",");
    countryList = regions.map((countryCode) => {
      const country = app.getCountryByIsoCode(countryCode),
        countryName = country ? country.getName() : countryCode,
        shortListed = _(countryShortList).contains(countryCode);
      let emoji;
      try {
        // converti un code iso en EMOJI pays
        emoji = app.isMobile()
          ? countryCode.toUpperCase().replace(/./g, function (char: string) {
              return String.fromCodePoint(char.charCodeAt(0) + 127397);
            })
          : "";
      } catch (err) {
        emoji = "";
      }
      return {
        _s: (shortListed ? "0" : "1") + countryName,
        country: country ? true : false,
        emoji: emoji,
        shortListed: shortListed,
        name: countryName,
        code: countryCode,
      };
    });
    countryList = _.sortBy(countryList, "_s");
    return countryList;
  },
};

export const usePhoneUtils = () => {
  const [instance, setInstance] = useState<PhoneNumberUtil | null>(null);
  useEffect(() => {
    phoneUtils.load().then((instance) => {
      setInstance(instance);
    });
  }, []);
  return instance;
};
export default phoneUtils;
