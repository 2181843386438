import { startTransition, useEffect, useMemo, useState } from "react";
import ResponsiveObserve, { ScreenMap } from "../_utils/responsiveObserve";

function useBreakpoint(): ScreenMap & {
  isMobile: boolean;
  isMobileAndMd: boolean;
  isMobileAndLg: boolean;
  devicePixelRatio: number;
} {
  const [screens, setScreens] = useState<ScreenMap>({});

  const isMobile: boolean = useMemo(() => {
    return !!screens.mobile;
  }, [screens]);

  const isMobileAndMd = useMemo(() => {
    return !!(
      (screens.xs || screens.sm || screens.md) &&
      !screens.lg &&
      !screens.xl &&
      !screens.xxl
    );
  }, [screens]);
  const isMobileAndLg = useMemo(() => {
    return !!(
      (screens.xs || screens.sm || screens.md || screens.lg) &&
      !screens.xl &&
      !screens.xxl
    );
  }, [screens]);

  useEffect(() => {
    const token = ResponsiveObserve.subscribe((supportScreens) => {
      startTransition(() => {
        setScreens((prevScreens) => {
          if (JSON.stringify(supportScreens) !== JSON.stringify(prevScreens)) {
            return supportScreens;
          }
          return prevScreens;
        });
      });
    });

    return () => ResponsiveObserve.unsubscribe(token);
  }, []);

  const data = useMemo(
    () => ({
      ...screens,
      isMobile,
      isMobileAndMd,
      isMobileAndLg,
      devicePixelRatio:
        typeof window !== "undefined" ? window.devicePixelRatio : 1,
    }),
    [screens]
  );

  return data;
}

export default useBreakpoint;
