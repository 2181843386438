import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ProductApiData } from "@src/interface/Product";

// adapter
export const productsAdapter = createEntityAdapter<ProductApiData>({
  selectId: (product) => product.id_product || "",
});

// hydratable field
const productRehydrateFields: Array<keyof ProductApiData> = [
  "price_ht",
  "price_ttc",
  "strikethrough_price",
  "strikethrough_price_vae",
  "price_ht_vae",
  "price_ttc_vae",
  "pictures",
];

// slice
export const ProductSlice = createSlice({
  name: "product",
  initialState: productsAdapter.getInitialState(),
  reducers: {
    setProducts: productsAdapter.setAll,
    addProduct: productsAdapter.addOne,
    addProducts: productsAdapter.addMany,
    updateProduct: productsAdapter.updateOne,
    rehydrateProduct(
      state,
      action: PayloadAction<{
        id: NonNullable<ProductApiData["id_product"]>;
        changes: Partial<ProductApiData>;
      }>
    ) {
      const { changes, id } = action.payload;
      const hydrateData: Record<string, any> = {};

      productRehydrateFields.forEach((k) => {
        if (changes[k]) {
          hydrateData[k] = changes[k];
        }
      });
      productsAdapter.updateOne(state, {
        id,
        changes: { ...hydrateData },
      });
    },
  },
});

const reducer = ProductSlice.reducer;

export const { setProducts, addProduct, addProducts, rehydrateProduct } =
  ProductSlice.actions;

export default reducer;
