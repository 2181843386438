import Backbone from "@src/backbone/prototypes/Backbone";
import LocalStorageModel from "../prototypes/LocalStorageModel";

export interface IMacaronModelAttributes extends Backbone.ObjectHash {
  id_macaron: string;
  name?: string;
  color?: string;
}

class MacaronModel extends LocalStorageModel<IMacaronModelAttributes> {
  name = "MacaronModel";
  route = "apiMacaronModel";
  idAttribute = "id_macaron";

  initialize(attributes?: any, options?: any): void {
    const model = this;
    super.initialize.apply(model, [attributes, options]);
  }

  getName(): string {
    return this.get("name") || "";
  }

  getColor(): string {
    return this.get("color") || "";
  }
}

MacaronModel.prototype.name = "MacaronModel";
MacaronModel.prototype.idAttribute = "id_macaron";

export default MacaronModel;
