import React, { MouseEvent, startTransition, useEffect, useState } from "react";
import { $champagne7, $white } from "../../Utils/color";
import FontIcon from "../FontIcon/FontIcon";
import Typography from "../Typography/Typography";
import {
  QuantifierButton,
  QuantifierRoot,
  QuantifierAdd,
  QuantifierMobileButton,
  QuantifierMobileRoot,
} from "./Quantifier.styles";
import Spinner from "../Spinner/Spinner";
import clsx from "clsx";

export interface IQuantifierProps {
  async?: boolean;
  loading?: boolean;
  useMobileTemplate?: boolean;
  quantity?: number;
  addOnly?: boolean;
  maxQuantity?: number;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  editable?: boolean;
  onChange?: (value: number) => void;
  addProductLabel?: string;
  zeroDisplayPlus?: boolean;
  removeProductLabel?: string;
  variant?: "dark" | "light";
  size?: "medium" | "small";
}
const defaultQuantity = 0;

const Quantifier: React.FC<IQuantifierProps> = ({
  quantity = 0,
  maxQuantity,
  onChange,
  addOnly = false,
  addProductLabel = "",
  removeProductLabel = "Retirer",
  useMobileTemplate = false,
  zeroDisplayPlus = false,
  async = false,
  loading = false,
  editable = true,
  variant = "light",
  size = "medium",
  ...props
}: IQuantifierProps) => {
  // const [isLoading, setLoading] = useState<boolean>(false);
  const [counter, setCounter] = useState(quantity || defaultQuantity);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (quantity !== counter) {
      startTransition(() => {
        setCounter(quantity);
      });
    }
    // setLoading(false);
  }, [quantity]);

  useEffect(() => {
    if (dropdownOpen) {
      const closeDropdown = (e: Event) => {
        setDropdownOpen(false);
      };
      window.addEventListener("click", closeDropdown);
      return () => {
        window.removeEventListener("click", closeDropdown);
      };
    }
  }, [dropdownOpen]);

  const preventDefault = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onClickMobile: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    preventDefault(e);
    console.log("onClickMobile");
    if (counter === 0 || addOnly) {
      onChange && onChange(counter + 1);
      !addOnly && !async && setCounter(counter + 1);
    } else {
      setDropdownOpen(!dropdownOpen);
    }
  };

  const onClickMinus: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    preventDefault(e);
    if (counter > 0) {
      const newCounter = counter - 1;
      onChange && onChange(newCounter);
      !async && setCounter(newCounter);
    }
  };

  const onClickPlus: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    preventDefault(e);
    const newCounter = counter + 1;
    // if (async && !addOnly) setLoading(true);
    if (maxQuantity) {
      if (counter < maxQuantity) {
        onChange && onChange(newCounter);
        !addOnly && !async && setCounter(newCounter);
      }
    } else {
      onChange && onChange(newCounter);
      !addOnly && !async && setCounter(newCounter);
    }
  };

  const disabledAll = loading;
  const disabledMax = counter === maxQuantity;
  const disabledMin = counter < 1;
  const isDark = variant === "dark";
  const textColor = isDark ? $white : $champagne7;
  const isSmall = size === "small";
  const displayAddProductLabel = !!addProductLabel && counter === 0;
  const displayMinusAndQuantity = !zeroDisplayPlus || counter > 0;

  return (
    <>
      <QuantifierRoot
        {...props}
        {...{ addOnly, useMobileTemplate, $loading: loading }}
      >
        {displayAddProductLabel ? (
          <QuantifierAdd
            onClick={onClickPlus}
            data-action="plus"
            disabled={disabledAll || disabledMax}
            $loading={loading}
          >
            <Typography as="span" variant="button" theme="dark">
              {addProductLabel}
            </Typography>
            {loading && <Spinner absolute={true} className={"spinner"} />}
          </QuantifierAdd>
        ) : (
          <>
            {displayMinusAndQuantity && (
              <>
                <QuantifierButton
                  onClick={onClickMinus}
                  data-action="minus"
                  disabled={disabledMin || disabledAll}
                >
                  <FontIcon icon="minus" size={16} color={$white} />
                </QuantifierButton>
                <Typography as="span" variant="button" theme="dark">
                  {counter}
                </Typography>
              </>
            )}
            <QuantifierButton
              onClick={onClickPlus}
              data-action="plus"
              disabled={disabledMax || disabledAll}
            >
              <FontIcon icon="plus" size={16} color={$white} />
            </QuantifierButton>
            {loading && <Spinner absolute={true} className={"spinner"} />}
          </>
        )}
      </QuantifierRoot>
      {useMobileTemplate && (
        <QuantifierMobileRoot
          className={clsx("ro-qty__mobile-root", props.className)}
        >
          <QuantifierMobileButton
            $addOnly={addOnly}
            $busy={loading}
            data-quantity={quantity}
            onClick={onClickMobile}
            disabled={disabledAll}
            $variant={variant}
            $size={size}
          >
            <span className={"ui-zone"}></span>
            {!addOnly && (
              <FontIcon
                icon={"plus"}
                size={isSmall ? 16 : 24}
                color={textColor}
              />
            )}
            <Typography
              variant={isSmall ? "button2" : "button"}
              style={{ justifyContent: "space-around" }}
              color={() => textColor}
            >
              {quantity}
            </Typography>
            {editable && (
              <FontIcon
                icon={addOnly ? "plus" : "arrowhead_down"}
                size={isSmall ? 16 : 24}
                color={textColor}
              />
            )}
            {loading && <Spinner className={"spinner"} />}
          </QuantifierMobileButton>
          {!addOnly && (
            <select
              value={quantity}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                e.preventDefault();
                const { value } = e.target,
                  newCounter = Number(value);
                // setLoading(true);
                onChange && onChange(newCounter);
                !async && setCounter(newCounter);
              }}
            >
              {[...Array((maxQuantity || counter + 9) + 1).keys()].map((i) => (
                <option
                  key={i}
                  value={i}
                  children={i === 0 ? removeProductLabel : i.toString()}
                />
              ))}
            </select>
          )}
        </QuantifierMobileRoot>
      )}
    </>
  );
};

export default Quantifier;
