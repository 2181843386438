import clsx from "clsx";
import styled, { CSSObject } from "styled-components";
import {
  Breakpoint,
  responsiveMap,
} from "../../Components/Grid/_utils/responsiveObserve";
import { $champagne7, $white } from "../../Utils/color";
import { rem } from "../../Utils/font";
import { ITypographyProps } from "./Typography";

interface Props
  extends Omit<ITypographyProps, "theme" | "color" | "fixVerticalAlign"> {
  $theme: ITypographyProps["theme"];
  $color: ITypographyProps["color"] | string;
  $fixVerticalAlign: ITypographyProps["fixVerticalAlign"];
  $ellipse?: ITypographyProps["ellipse"];
  $ellipseLine?: ITypographyProps["ellipseLine"];
}

export type ITypographyVariants =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6light"
  | "h6"
  | "h7"
  | "h7bold"
  | "body"
  | "body2"
  | "button"
  | "button2"
  // "caption" |
  | "subtitle1"
  | "subtitle2"
  | "subtitle3"
  | "subtitle4"
  | "ui"
  | "ui2"
  | "ui3"
  | "ui4"
  | "ui5"
  | "lumios"
  | "lumios2"
  | "lumios3"
  | "lumios4"
  | "lumios5"
  | "lumios6"
  | "h3umami";

export const TypographyScale: Record<ITypographyVariants, CSSObject> = {
  lumios: {
    fontFamily: "lumios-brush, sans-serif",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: rem(64),
    lineHeight: rem(79.36),
  },
  lumios2: {
    fontFamily: "lumios-brush, sans-serif",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: rem(40),
    lineHeight: rem(49.6),
  },
  lumios3: {
    fontFamily: "lumios-brush, sans-serif",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: rem(32),
    lineHeight: rem(39.68),
  },
  lumios4: {
    fontFamily: "lumios-brush, sans-serif",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: rem(24),
    lineHeight: rem(29.76),
  },
  lumios5: {
    fontFamily: "lumios-brush, sans-serif",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: rem(36),
    lineHeight: rem(36),
  },
  lumios6: {
    fontFamily: "lumios-brush, sans-serif",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: rem(48),
    lineHeight: rem(60),
  },
  h1: {
    fontSize: rem(50),
    lineHeight: rem(64),
    fontWeight: 500,
    textTransform: "uppercase",
    breakpoints: {
      lg: {
        fontSize: rem(66),
        lineHeight: rem(76),
      },
    },
  },
  h2: {
    fontSize: rem(34),
    lineHeight: rem(40),
    fontWeight: 500,
    textTransform: "uppercase",
    breakpoints: {
      lg: {
        fontSize: rem(50),
        lineHeight: rem(58),
      },
    },
  },
  h3: {
    fontSize: rem(34),
    lineHeight: rem(40),
    breakpoints: {
      lg: {
        fontSize: rem(40),
        lineHeight: rem(46),
      },
    },
  },
  h4: {
    fontSize: rem(24),
    lineHeight: rem(32),
    letterSpacing: "-0.96px",
    breakpoints: {
      lg: {
        fontSize: rem(34),
        lineHeight: rem(40),
        letterSpacing: "-0.96px",
      },
    },
  },
  h5: {
    fontSize: rem(24),
    lineHeight: rem(32),
  },
  h6light: {
    fontSize: rem(20),
    lineHeight: rem(24),
    letterSpacing: "-0.02rem",
    fontWeight: 300,
  },
  h6: {
    fontSize: rem(20),
    lineHeight: rem(24),
    letterSpacing: "-0.02rem",
  },
  h7: {
    fontSize: rem(17),
    lineHeight: rem(24),
  },
  h7bold: {
    fontSize: rem(17),
    lineHeight: rem(24),
    fontWeight: 500,
  },
  body: {
    fontSize: rem(17),
    lineHeight: rem(24),
    letterSpacing: "-0.17px",
  },
  body2: {
    fontSize: rem(15),
    lineHeight: rem(21),
  },
  subtitle1: {
    fontSize: rem(20),
    lineHeight: rem(28),
    letterSpacing: "-0.02rem",
    breakpoints: {
      lg: {
        fontSize: rem(24),
        lineHeight: rem(30),
      },
    },
  },
  subtitle2: {
    fontSize: rem(17),
    lineHeight: rem(24),
    letterSpacing: "-0.02rem",
  },
  subtitle3: {
    fontSize: rem(15),
    lineHeight: rem(17),
  },
  subtitle4: {
    fontSize: rem(14),
    lineHeight: rem(24),
    fontWeight: 400,
  },
  ui: {
    fontSize: rem(11),
    lineHeight: rem(16),
    fontWeight: 500,
    letterSpacing: "0.12rem",
    textTransform: "uppercase",
  },
  ui2: {
    fontSize: rem(12),
    lineHeight: rem(14),
    letterSpacing: "0.04rem",
    textTransform: "uppercase",
  },
  ui3: {
    fontSize: rem(14),
    lineHeight: rem(16),
    letterSpacing: "0.04rem",
  },
  ui4: {
    fontSize: rem(12),
    lineHeight: rem(16),
    letterSpacing: "0.01rem",
  },
  ui5: {
    fontSize: rem(20),
    lineHeight: rem(25),
    fontWeight: 600,
    textTransform: "uppercase",
  },
  button: {
    fontSize: rem(17),
    lineHeight: rem(17),
    letterSpacing: "-0.01rem",
  },
  button2: {
    fontSize: rem(15),
    lineHeight: rem(17),
    letterSpacing: "-0.01rem",
  },
  h3umami: {
    fontSize: rem(28),
    fontWeight: 500,
    lineHeight: rem(32),
    letterSpacing: "-0.84px",
    breakpoints: {
      lg: {
        fontSize: rem(40),
        lineHeight: rem(46),
        fontWeight: 500,
        letterSpacing: "-1.2px",
      },
    },
  },
};

export const TypographyRoot = styled.div.attrs<Props, Props>(({ ...props }) => {
  const variantClassList: string[] = [];
  const generateClass = (suff: string) => `ro-typography--${suff}`;
  if (typeof props.variant === "string") {
    variantClassList.push(generateClass(props.variant));
  } else {
    variantClassList.push(
      ...Object.entries(props.variant || {}).map<string>(([k, v]) =>
        generateClass(`${k}--${v}`)
      )
    );
  }
  return {
    ...props,
    className: clsx("ro-typography", props.className, variantClassList),
  };
})(
  ({
    variant = "body",
    align = "inherit",
    gutterBottom = false,
    userSelect,
    cursor,
    $theme,
    $color,
    mb,
    mt,
    $fixVerticalAlign,
    $ellipse = false,
    $ellipseLine = 1,
    $hover = false,
  }) => {
    const variantProps = TypographyScale;
    const mainVariantStyle =
      typeof variant === "string" ? variantProps[variant] : {};

    let styledObj: CSSObject = {
      textAlign: align,
      marginBottom: mb,
      marginTop: mt,
      userSelect,
      cursor,
    };

    styledObj = Object.assign({}, styledObj, mainVariantStyle);
    if (styledObj.breakpoints) {
      Object.entries(styledObj.breakpoints).forEach(([point, styles]) => {
        if (responsiveMap[point as Breakpoint]) {
          styledObj[`@media ${responsiveMap[point as Breakpoint]}`] = styles;
        }
      });
    }

    if (typeof variant === "object") {
      Object.entries(variant).forEach(([p, v]) => {
        if (variantProps[v] && responsiveMap[p as Breakpoint]) {
          let query = `@media ${responsiveMap[p as Breakpoint]}`;
          styledObj[query] = {
            ...((styledObj[query] as object) || {}),
            ...variantProps[v],
          };
        }
      });
    }

    if ($fixVerticalAlign) {
      styledObj.transform = "translateY(-2px)";
    }

    let ellipseStyle: CSSObject = $ellipse
      ? {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          "-webkit-line-clamp": `${$ellipseLine}` /* number of lines to show */,
          lineClamp: `${$ellipseLine}`,
          "-webkit-box-orient": "vertical",
          // paddingBottom: 3,
        }
      : {};

    const inheritsStyles = {
      fontSize: "inherit",
      lineHeight: "inherit",
      fontWeight: "inherit",
      margin: 0,
    };

    return {
      ...inheritsStyles,
      ...styledObj,
      ...mainVariantStyle,
      ...ellipseStyle,
      textDecoration: !$hover && "none",
      color: $color || ($theme === "dark" ? $white : $champagne7),
      ["&[type='button']"]: {
        border: "none",
        background: "none",
        padding: 0,
      },
    } as CSSObject;
  }
);

export const typoWrap = (tag: keyof JSX.IntrinsicElements) => {
  return styled[tag]`
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    margin: 0;
  `;
};
