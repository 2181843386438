import ThemeProvider from "design-system/src/General/ThemeProvider/ThemeProvider";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import AlertNoticeProvider from "./lib/alert-notice/AlertNoticeProvider";
import store from "./store";
import ModelProvider from "./backbone/provider/ModelProvider";
import { ImageUrlProvider } from "@src/lib/render-image/Context/ImageUrlProvider";
import LinkContext from "design-system/src/Core/Link/Context/LinkContext";
import { StyledLink, LinkWrapper } from "@src/lib/rolling-start-navigation";
import TypographyLink from "@src/lib/rolling-start-navigation/TypographyLink";
import FlexBoxLink from "@src/lib/rolling-start-navigation/FlexBoxLink";
import { IntlProvider } from "gatsby-plugin-intl-sushishop";
import { GATSBY_INTL_DEFAULT_LANG } from "@src/utils/constants";

export type RunContext = "ssr" | "browser";

interface IAppProviderProps {
  children: React.ReactElement;
}

const AppProvider = (props: IAppProviderProps) => {
  const { children } = props;
  return (
    <IntlProvider locale={GATSBY_INTL_DEFAULT_LANG}>
      <Provider store={store}>
        <ThemeProvider>
          <ImageUrlProvider>
            <LinkContext.Provider
              value={{ LinkWrapper, StyledLink, TypographyLink, FlexBoxLink }}
            >
              <AlertNoticeProvider>
                <ModelProvider>{children}</ModelProvider>
              </AlertNoticeProvider>
            </LinkContext.Provider>
          </ImageUrlProvider>
        </ThemeProvider>
      </Provider>
    </IntlProvider>
  );
};

export default React.memo(AppProvider);
