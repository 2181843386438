import React, { ReactNode, useRef, useEffect, CSSProperties } from "react";
import { responsiveMap } from "../../Components/Grid/_utils/responsiveObserve";
import { IDefaultTheme, useTheme } from "../../Themes/defaultTheme";
import { ITypographyVariants, TypographyRoot } from "./Typography.styles";
import { useTypographyContext } from "./Typography.context";

export const TypographyVariantsList: ITypographyVariants[] = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "h7",
  "h7bold",
  "body",
  "body2",
  "button",
  "button2",
  // "caption",
  "subtitle1",
  "subtitle2",
  "subtitle3",
  "ui",
  "ui2",
  "ui3",
  "ui4",
];
type IBreakpoint = keyof typeof responsiveMap;

export interface ITypographyProps
  extends Omit<React.HTMLAttributes<HTMLElement>, "color"> {
  theme?: "dark" | "light";
  align?: "center" | "inherit" | "justify" | "left" | "right";
  gutterBottom?: boolean;
  paragraph?: boolean;
  className?: string;
  as?: string | React.ComponentType<any>;
  variant?: ITypographyVariants | { [_ in IBreakpoint]?: ITypographyVariants };
  dangerouslySetInnerHTML?: React.DOMAttributes<HTMLDivElement>["dangerouslySetInnerHTML"];
  color?: keyof IDefaultTheme["color"] | (() => string);
  style?: CSSProperties;
  mb?: CSSProperties["marginBottom"];
  mt?: CSSProperties["marginTop"];
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  fixVerticalAlign?: boolean;
  ellipse?: boolean;
  ellipseLine?: number;
  showSeeMore?: boolean;
  showMoreLabel?: string;
  showLessLabel?: string;
  renderSeeMore?: () => ReactNode;
  onClamped?: (value: boolean) => void;
  href?: string;
  to?: string;
  htmlFor?: string;
  type?: "button" | "submit";
  $hover?: boolean;
  userSelect?: CSSProperties["userSelect"];
  cursor?: CSSProperties["cursor"];
  useContextColor?: boolean;
  strikethrough?: boolean;
}

const Typography = ({
  children,
  paragraph,
  dangerouslySetInnerHTML,
  theme,
  color,
  style,
  fixVerticalAlign,
  ellipse,
  ellipseLine = 1,
  showSeeMore,
  onClamped,
  useContextColor,
  strikethrough,
  ...props
}: ITypographyProps) => {
  const partialProps: Partial<Omit<ITypographyProps, "theme">> = {};
  const { color: themeColor } = useTheme();
  const { color: contextColor } = useTypographyContext();
  const elementRef = useRef();
  const $color =
    typeof color === "string" && themeColor[color as keyof typeof themeColor]
      ? useContextColor
        ? contextColor || themeColor[color as keyof typeof themeColor]
        : themeColor[color as keyof typeof themeColor] || contextColor
      : typeof color === "function"
      ? useContextColor
        ? contextColor || color()
        : color() || contextColor
      : useContextColor
      ? contextColor || color
      : color || contextColor;
  if (paragraph) {
    partialProps.gutterBottom = true;
    partialProps.as = "p";
  }

  useEffect(() => {
    if (elementRef.current && showSeeMore && onClamped) {
      const _el = elementRef.current;
      const observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.target.scrollHeight > entry.contentRect.height) {
            onClamped(true);
          } else {
            onClamped(false);
          }
        }
      });

      observer.observe(_el);
      return () => {
        if (_el) {
          observer.unobserve(_el);
        }
      };
    }
  }, []);

  return (
    <TypographyRoot
      $theme={theme}
      ref={elementRef}
      $color={$color}
      $ellipse={ellipse}
      $ellipseLine={ellipseLine}
      style={{
        ...style,
        // strikethrough style
        ...(strikethrough && {
          textDecoration: "line-through",
        }),
      }}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      $fixVerticalAlign={fixVerticalAlign}
      {...props}
      {...partialProps}
      children={!dangerouslySetInnerHTML ? children : undefined}
    />
  );
};

Typography.displayName = "Typography";

export default Typography;
