import LocalStorageModel from "../prototypes/LocalStorageModel";
import UrlFactory from "../prototypes/UrlFactory";
import { app } from "./AppModel";
import CategoryModel from "./CategoryModel";
import _ from "underscore";
// import Modernizr from "modernizr";
import { site_vars } from "../provider/siteVars";
import IngredientCollection from "./IngredientCollection";
import { defaultCart } from "../provider/ModelProvider";
import AllergenGroupCollection from "./AllergenGroupCollection";
import TagCollection from "./TagCollection";
import { sprintf } from "sprintf-js";
import CartModel from "./CartModel";
import formatPrice from "../prototypes/formatPrice";
import SidedishCollection from "./SidedishCollection";
import ProductBoostModel from "./ProductBoostModel";
import { PriceType } from "./StoreModel";
import { urlWithSyncVersion } from "@src/backbone/prototypes/ObjectPrototype";
import { ISideDish } from "@src/interface/Product";
import SidedishModel from "./SidedishModel";
import {
  GATSBY_CONF_ENERGY_CONVERSION_RATE,
  GATSBY_CONF_ENERGY_DECIMALS,
  GATSBY_CONF_ENERGY_DECIMALS_SIGN,
  GATSBY_CONF_ENERGY_ROUND_ZERO,
  GATSBY_CONF_ENERGY_UNIT,
  GATSBY_CONF_IS_AVIS_VERIFIE_ACTIVE,
} from "@src/utils/constants";

interface IVisibilityItem {
  from?: string | null;
  to?: string | null;
  display_message?: boolean | null;
  day?: number | null;
  enabled?: boolean;
  am?: boolean;
  pm?: boolean;
  hMin: number;
  mMin: number;
  hMax: number;
  mMax: number;
}

type IProductVisibility =
  | "always"
  | "never"
  | IVisibilityItem
  | Array<IVisibilityItem>
  | undefined
  | null;

interface IRisingSunPicture {
  id_object_picture: string;
  types: string[];
  cover: boolean;
  legend: string;
}

interface IProductAttributes extends Backbone.ObjectHash {
  id_product?: string | null;
  reference?: string | null;
  name?: string | null;
  categories?: any[];
  display_avis_verifie?: boolean | null;
  has_payment_restrictions?: boolean | null;
  customer_group?: string[] | null;
  enable_vae?: boolean | null;
  enable_vad?: boolean | null;
  active_iphone?: boolean | null;
  active_cross_selling?: boolean | null;
  active_web?: boolean | null;
  price_ttc?: string | null;
  price_ttc_vae?: string | null;
  price_ht?: string | null;
  price_ht_vae?: string | null;
  id_tax?: string | null;
  id_tax_vae?: string | null;
  id_category_default?: string | null;
  block_type?: number | null;
  nutritional_data?: {
    line?: any[] | null;
    col?: any[] | null;
  } | null;
  sidedishes?: ISideDish[] | null;
  quantity?: number | null;
  link_rewrite?: string | null;
  visibility?: IProductVisibility | null;
  fixed_recipe?: boolean | null;
  preorder_type?: number | null;
  display_outdated?: boolean | null;
  print_if_no_store?: boolean | null;
  tag?: string[];
  pictures?: Array<{
    id_object_picture: string;
    legend: string;
    types: string[];
    cover: boolean;
  }>;
}

//
// Model Product
//
var SUFFIX_BLOCK_INGREDIENT = "block_ingredient_";

var TRANSLTATION_PRODUCT_CONTEXT = "Product",
  TRANSLATION_STOCKOUT_LABEL = "Victime de son succès";

var IMG_200x200 = "200x200",
  IMG_120x120 = "120x120",
  IMG_60x60 = "60x60";

export interface ProductPaymentRestriction {
  id_payment_mode: string;
  force_if_empty: "0" | "1";
}

class ProductModel extends LocalStorageModel<IProductAttributes> {
  backbonePattern = "Model";
  name = "ProductModel";
  idAttribute = "id_product";
  route = "apiProductModel";
  isModelFetched = false;
  allergenGroups?: AllergenGroupCollection;
  tags?: TagCollection;
  sidedishes?: SidedishCollection;

  has_payment_restrictions?: boolean;
  payment_restrictions?: ProductPaymentRestriction[];
  productBoost: any;

  hasPaymentRestriction() {
    return this.get("has_payment_restrictions");
  }
  getPaymentRestrictions(): ProductPaymentRestriction[] | undefined {
    return this.get("payment_restrictions");
  }
  initialize(attributes?: any, options?: any): void {
    // ObjectModel constructor heritage
    var model = this;
    super.initialize.apply(model, [attributes, options]);
    //
    if (!model.get(model.idAttribute)) {
      model.attributes[model.idAttribute] = model.get("id");
    }
    if (model.get("is_menu")) {
      model.listenTo(app.getCart(), "change:id_store", model.cleanSidedishes);
    }
    model.on("sync", model.onSync);
  }
  onSync() {
    this.isModelFetched = true;
  }
  // isSync() {
  //   return this.isModelFetched === true;
  // }
  // getAdditifs() {
  //   return this.get("additifs") || "";
  // }
  getExtraClass() {
    const classProduct = this.get("class");
    if (classProduct) {
      return classProduct.join(",");
    } else {
      return "";
    }
  }
  // useBlockType() {
  //   const blockType = this.get("block_type") || 0;
  //   return blockType > 0;
  // }
  getBlockType(ignoreBlockType?: any) {
    var blockType = this.get("block_type"),
      blockTypeName;
    switch (ignoreBlockType ? 0 : blockType) {
      case 0:
      default:
        blockTypeName = "default";
        break;
      case 1:
        blockTypeName = "double";
        break;
      case 2:
        blockTypeName = "zoom2x";
        break;
    }
    return blockTypeName;
  }
  getBlockClass(ignoreBlockType: any) {
    var product = this,
      cart = app.getCart(),
      classes = [
        "block-type--" + this.getBlockType(ignoreBlockType),
        product.isBuyableInContext(cart) ? "buyable" : "not-buyable",
        product.allowPreorder() ? "allow-preorder" : "",
        product.isStockout() ? "is-stockout" : "",
        product.getAvisverifie() ? "has-avisverifie" : "",
      ];
    return classes.join(" ");
  }
  // getAccompagnement() {
  //   return "";
  // }
  // getType() {
  //   return "";
  // }
  getName() {
    return this.get("name");
  }
  // getTitle() {
  //   return this.get("titre_seo") || this.getName();
  // }
  getPieces() {
    return this.get("quantity") || 0;
  }
  displayPieces(options?: { min?: number; wrapper?: string }) {
    const { min = 1, wrapper = "" } = { ...options };
    const q = this.getQuantity(),
      isPlural = q > 1;
    let label =
      q >= min
        ? app.t(
            isPlural ? "%s pièces" : "%s pièce",
            TRANSLTATION_PRODUCT_CONTEXT,
            q
          )
        : "";
    if (!!label && !!wrapper) {
      label = sprintf(wrapper, label);
    }
    return label;
  }
  getTemplate() {
    // TODO: get template
    /* var model = this,
      templateSProduct = model.get("template");

    if (templateSProduct) {
      return templates[templateSProduct];
    } else {
      return false;
    } */
    return false;
    //  return templates.ComponentsVodTemplatesSidedishBlock;
  }
  getDescription() {
    return this.get("description");
  }
  // getSmallDescription() {
  //   return this.get("description_short");
  // }
  // canBeOffered() {
  //   return app.getCart().productCanBeOffered() && this.isLiked();
  // }
  getMacaron() {
    const id_macaron = this.get("id_macaron");
    if (id_macaron) {
      return app.getMacaron(id_macaron);
    }
  }
  formatMacaron() {
    var macaron = this.getMacaron();
    if (macaron) {
      return macaron.getName();
    } else {
      return "";
    }
  }
  isActiveVae() {
    return this.get("enable_vae");
  }
  isActiveVad() {
    return this.get("enable_vad");
  }
  getCategories() {
    return app.getCategories().filterByProduct(this.id.toString());
  }

  getCategoryDefault() {
    var model = this;
    var default_category: CategoryModel | undefined = undefined;
    if (model.get("id_category_default")) {
      default_category = app.getCategory(model.get("id_category_default"));
    }
    // si la catégorie par défaut n'est pas disponible, on cherche à utiliser une autre catégorie
    if (!default_category) {
      default_category = model.getCategories()[0];
    }
    return default_category;
  }
  getLinkRewrite() {
    return this.get("link_rewrite");
  }
  getUrl() {
    const model = this;
    let url = model.get("url"); // cached url
    if (!url) {
      url = model.get("deleted")
        ? "#"
        : UrlFactory.getUrl("product", model.getUrlOptions());
      model.set("url", url);
    }
    return url;
  }
  getUri() {
    return UrlFactory.getUrl("product", this.getUrlOptions(), true);
  }
  getUrlOptions() {
    const product = this,
      category = product.getCategoryDefault(),
      categoryRewrite = category ? category.getLinkRewrite() : "product";
    return {
      category_link_rewrite: categoryRewrite,
      product_link_rewrite: product.getLinkRewrite(),
      id_product: product.id,
    };
  }
  getPicture(type?: string): IRisingSunPicture | undefined {
    return this.getPictures(type)[0];
  }
  getPictures(type: string = "rollingstart") {
    return (this.get("pictures") as IRisingSunPicture[]).filter((p) => {
      return p.types.includes(type) || type === "*";
    });
  }
  getPictureByType(type: string) {
    const typedPictures = this.getPictures(type);
    return typedPictures[0] || false;
  }
  getCustomProductPictureUrl(type?: string) {
    const productCustomPicture = this.getPictureByType("productCustom");
    return this.getPictureUrl(type || "single", productCustomPicture);
  }
  getPicturesExtended(origin: any) {
    const model = this;
    return _(
      _(
        model.getPictures().map(function (picture) {
          const legend = picture.legend,
            url = model.getPictureUrl(origin, picture);
          return {
            img: '<img src="' + url + '" alt="' + legend + '" />',
            legend: legend,
            url: url,
          };
        })
      )
    );
  }
  getPictureUrl(origin: string, picture?: any) {
    if (_.isUndefined(picture)) {
      picture = this.getPicture();
    }
    let format, url;
    if (origin === "thumb" || origin === "line") {
      // TODO: check retina
      // format = Modernizr.retina ? IMG_120x120 : IMG_60x60;
      format = IMG_60x60;
    } else if (origin === "listing") {
      if (app.isDesktop()) {
        switch (this.getBlockType()) {
          default:
            format = IMG_200x200;
            break;
          case "zoom2x":
            format = "280x280";
            break;
          case "double":
            format = "300x150";
            break;
        }
      } else {
        // TODO: check retina
        // format = Modernizr.retina ? IMG_120x120 : IMG_60x60;
        format = IMG_60x60;
      }
    } else if (origin === "single") {
      format = "400x400";
    } else if (origin === "large") {
      format = "1080x1080";
    } else if (origin === "consumable") {
      format = app.isDesktop() ? "80x80" : "100x100";
    } else {
      // origin == block
      format = IMG_200x200;
    }
    if (picture) {
      url = app.getPictureUrl(
        picture.id_object_picture,
        "Product",
        format,
        "PNG",
        this.getLinkRewrite()
      );
    } else {
      url = site_vars.img_url + "product-default.png";
    }

    return url;
  }
  getAllIngredient() {
    var model = this,
      ingredientCollection = new IngredientCollection();
    _([1, 2]).each(function (n) {
      _(model.get(SUFFIX_BLOCK_INGREDIENT + n)).each(function (item) {
        if (typeof item === "string") {
          ingredientCollection.add(app.getIngredient(item));
        } else if (item?.id_ingredient) {
          ingredientCollection.add(app.getIngredient(item.id_ingredient));
        }
      });
    });
    return ingredientCollection;
  }
  getAllIngredientsCount() {
    var model = this;
    return _([1, 2]).reduce(function (sum, n) {
      const ingredients: any[] = model.get(SUFFIX_BLOCK_INGREDIENT + n) || [];
      return sum + (ingredients.length || 0);
    }, 0);
  }
  getAllIngredientsName() {
    return this.getAllIngredient()
      .map(function (i: { get: (arg0: string) => any }) {
        return i.get("name");
      })
      .join(", ");
  }
  getBlockIngredient(n: string) {
    var ingredientCollection = new IngredientCollection();
    _(this.get(SUFFIX_BLOCK_INGREDIENT + n)).each(function (id_ingredient) {
      ingredientCollection.add(app.getIngredient(id_ingredient));
    });
    return ingredientCollection;
  }
  getAllergenGroups() {
    var model = this,
      allergenGroups = model.allergenGroups;
    if (!allergenGroups) {
      allergenGroups = model.allergenGroups = app
        .getAllergenGroups()
        .whereIds(this.get("allergengroup"));
    }
    return allergenGroups;
  }
  getTags() {
    var model = this,
      tags = model.tags;
    if (!tags) {
      tags = model.tags = app.getTags().whereIds(this.get("tag") || []);
    }
    return tags;
  }
  getAvisverifie() {
    var _AVIS_VERIFIE_MIN_REVIEW_ = parseInt(
        app.c("_AVIS_VERIFIE_MIN_REVIEW_")
      ),
      _AVIS_VERIFIE_MIN_AVG_ = parseFloat(app.c("_AVIS_VERIFIE_MIN_AVG_")),
      count_avis_verifie = parseInt(this.get("count_avis_verifie")),
      avg_avis_verifie = parseFloat(this.get("avg_avis_verifie"));

    return GATSBY_CONF_IS_AVIS_VERIFIE_ACTIVE &&
      count_avis_verifie > _AVIS_VERIFIE_MIN_REVIEW_ &&
      avg_avis_verifie > _AVIS_VERIFIE_MIN_AVG_
      ? avg_avis_verifie.toFixed(1)
      : false;
  }
  getQuantity() {
    return this.get("quantity") || 0;
  }
  getCartQuantity() {
    var model = this,
      qty = 0,
      cart = app.getCart();
    if (model.isMenu(true)) {
      cart
        .getProducts()
        .each((cartProduct: { get: (arg0: string) => string | number }) => {
          qty += cartProduct.get("id_product") === model.id ? 1 : 0;
        });
    } else {
      var cartProduct = cart.getProduct("" + model.id);
      qty = cartProduct ? cartProduct.getQuantity() : 0;
    }
    return qty;
  }
  // distingue les CartProduct des Product
  editableQuantity() {
    return false;
  }
  deletable() {
    return false;
  }
  isCartProduct() {
    return false;
  }
  //
  getKiloJoules() {
    return parseFloat(this.get("kilojoules"));
  }
  displayEnergy() {
    const kJ = this.getKiloJoules(),
      _ENERGY_DECIMALS_SIGN_ = GATSBY_CONF_ENERGY_DECIMALS_SIGN,
      _ENERGY_UNIT_ = GATSBY_CONF_ENERGY_UNIT,
      kJtoUnit = (kJ / GATSBY_CONF_ENERGY_CONVERSION_RATE).toFixed(
        GATSBY_CONF_ENERGY_DECIMALS
      );
    let Energy = kJtoUnit.toString().replace(/[,\.]/, _ENERGY_DECIMALS_SIGN_);
    if (GATSBY_CONF_ENERGY_ROUND_ZERO) {
      // remove final Zero
      Energy = Energy.replace(new RegExp(_ENERGY_DECIMALS_SIGN_ + "0+$"), "");
    }
    return sprintf(_ENERGY_UNIT_, Energy);
  }
  // getHtmlImg(origin: any) {
  //   return (
  //     '<img src="' +
  //     this.getPictureUrl(origin) +
  //     '" title="' +
  //     this.getName() +
  //     '" />'
  //   );
  // }
  getPrice(
    type: PriceType = "ttc",
    inContext?: boolean,
    cart?: CartModel
  ): number {
    const product = this;
    let store, price;
    inContext = inContext === true;

    if (inContext) {
      cart = defaultCart(cart);
      store = cart.getStore();
    }
    let checkedType = type;

    if (
      inContext &&
      cart &&
      cart.isVAE() &&
      cart.hasStore() &&
      type === "ttc"
    ) {
      checkedType = "ttc_vae";
    }

    if (inContext && store && store.hasModifiers()) {
      price = store.getProductPrice(product, checkedType);
    } else {
      price = parseFloat(product.get("price_" + checkedType));
    }

    return price;
  }
  getStrikethroughPrice(inContext?: boolean, cart?: CartModel): number {
    const product = this;
    inContext = inContext === true;
    let store,
      mode = "";

    if (inContext) {
      cart = defaultCart(cart);
      store = cart.getStore();
    }

    if (inContext && cart && cart.isVAE() && cart.hasStore()) {
      mode = "_vae";
    }
    return parseFloat(product.get("strikethrough_price" + mode));
  }

  getUnavailableMessage() {
    const defaultMessage = app.t("Produit non disponible", "Checkout");
    return this.isStockoutNow()
      ? app.t(TRANSLATION_STOCKOUT_LABEL, TRANSLTATION_PRODUCT_CONTEXT)
      : this.get("indisponibility_message") || defaultMessage;
  }
  getExtraDisplayPrice() {
    var extraDisplayPrice = this.get("extradisplayprice") || [],
      r: Array<any> = [];
    // Todo
    // attention c'est assez dangereux comme modele de données,
    // car on se fie sur la clé / valeur avec le postulat qu'elle est unique
    _(extraDisplayPrice).each(function (object, index) {
      var key = _.keys(object)[0],
        value = parseFloat(_.values(object)[0]);
      //
      r.push({
        name: key,
        price_ttc: value,
      });
    });
    return _(r);
  }
  formatPriceValue(value: any) {
    return formatPrice(value);
  }
  formatPrice(
    type?: PriceType,
    inContext?: boolean,
    cart?: CartModel,
    quantity: number = 1
  ) {
    return formatPrice(this.getPrice(type, inContext, cart) * quantity);
  }
  formatStrikethroughPrice(
    inContext?: boolean,
    cart?: CartModel,
    quantity: number = 1
  ): string {
    const price = this.getStrikethroughPrice(inContext, cart) * quantity;
    return price > 0 ? formatPrice(price) : "";
  }
  formatPriceQty(type: PriceType, qty: number, inContext?: boolean) {
    return formatPrice(this.getPrice(type, inContext) * qty);
  }

  formatSidedishPrice(wrapperString: any, sidedish: SidedishModel) {
    const product = this,
      product_price = product.getPrice("ttc", true);
    let price_label = "";
    if (product.isStockout()) {
      price_label = app.t(
        TRANSLATION_STOCKOUT_LABEL,
        TRANSLTATION_PRODUCT_CONTEXT
      );
    } else if ((sidedish && sidedish.isFree()) || product_price === 0) {
      if (sidedish && sidedish.isFreeDisplay()) {
        price_label = app.t("offert", TRANSLTATION_PRODUCT_CONTEXT);
      }
    } else {
      price_label = formatPrice(product.getPrice("ttc", true), {
        auto_round_decimal: true,
      });
      if (sidedish && sidedish.isPlusDisplay()) {
        price_label = "+" + price_label;
      }
    }
    return price_label !== "" ? sprintf(wrapperString, price_label) : "";
  }

  isMenu(ignoreSidedish?: boolean) {
    // cette fonction est contextuelle,
    // elle dépend des produits avec accompagnement midi/soir variable
    return (
      this.get("is_menu") && (ignoreSidedish || !!this.getSidedishes().size())
    );
  }
  hasSteps() {
    var product = this;
    return (
      (product.isMenu() && !product.isCustomBox42()) ||
      product.isCustomBox42Menu()
    );
  }
  countSteps() {
    var product = this,
      count = 0,
      sidedishes;
    if (product.isMenu()) {
      sidedishes = product.getSidedishes();
      count = sidedishes.sizeRoot();
      count += product.isCustomBox42() ? 0 : 1;
    }
    return count;
  }
  isCustomBox42() {
    var model = this,
      isCustomBox42 = model.isMenu();
    if (isCustomBox42) {
      var firstSidedish = model.getSidedishes().at(0);
      isCustomBox42 =
        !!firstSidedish && firstSidedish.get("template") === "custom_box_42";
    }
    return isCustomBox42;
  }
  isCustomBox42Menu() {
    // permet de savoir si le produit Box à composer
    // a aussi des cross-selling en plus
    var model = this,
      result = false;
    if (model.isCustomBox42()) {
      model
        .getSidedishes()
        .each(function (sidedish: { get: (arg0: string) => string }) {
          if (sidedish.get("template") !== "custom_box_42") {
            result = true;
          }
        });
    } else {
      result = false;
    }
    return result;
  }
  isLiked() {
    var customer = app.getCustomer();
    return customer.isLogged() && customer.isProductLiked(this.id + "");
  }
  isInCategory(category: CategoryModel) {
    return _(this.getCategories()).contains(category);
  }
  getSidedishes(forceReset = false): SidedishCollection {
    const model = this;
    let sidedishCollection = model.sidedishes;
    if (!model.sidedishes || forceReset) {
      // internal cache
      sidedishCollection = model.resetSidedishes();
    }
    return sidedishCollection || new SidedishCollection([]);
  }
  resetSidedishes(accompagnements?: unknown) {
    const product = this,
      sidedishes = product.get("sidedishes");
    let initialSerializedSelection = "";
    if (
      !accompagnements &&
      !!(initialSerializedSelection = product.get("initialSerializedSelection"))
    ) {
      initialSerializedSelection = JSON.parse(initialSerializedSelection);
      product.unset("initialSerializedSelection");
    }
    return (this.sidedishes = SidedishCollection.prepareSelection(
      sidedishes,
      app,
      accompagnements,
      initialSerializedSelection
    ));
  }
  cleanSidedishes() {
    this.sidedishes = undefined;
  }
  getProductBoost(): ProductBoostModel {
    const model = this,
      product_boost = model.get("product_boost") || undefined;
    let productBoost = product_boost ? model.productBoost : product_boost;
    if (product_boost && !productBoost) {
      product_boost.id_product_source = model.id;
      productBoost = new ProductBoostModel(product_boost);
      // le boost est unique, par produit, pendant la durée de la navigation
      model.productBoost = productBoost;
    }
    return productBoost;
  }
  // requireProductBoost() {
  //   return !!this.getProductBoost()?.requireProductBoost();
  // }
  getProductStore() {
    const product = this,
      cart = app.getCart(),
      store = cart.getStore(),
      cartValidate = cart.validateDeliveryOptions();
    let productStore;

    if (store && store.hasModifiers() && cartValidate) {
      // product
      productStore = store.getProduct(product);
      // si on a une boutique et qu'on a pas le produit,
      // il est implicitement concidéré comme désactivé du contexte
    }
    return !productStore ? false : productStore;
  }
  sideDishWithoutContext() {
    return this.get("sidedishes");
  }
  // check if there is at least one attached sidedish
  sideDishWithAttachedWithoutContext() {
    return this.sideDishWithoutContext()?.filter((s) => s.is_attached);
  }
  isStockout(preventRecursion?: boolean) {
    // par défaut, si le produit n'est pas dans le catalogue
    // ou qu'il n'y a pas de boutique valide, le produit n'est pas en rupture de stock
    const model = this,
      productStore = model.getProductStore();
    let isStockout = productStore ? !!productStore.isStockout : false;
    if (!isStockout && model.isMenu()) {
      model.getSidedishes().each(function (sidedish) {
        if (!isStockout && sidedish.getCategory()) {
          // si le sidedish est obligatoire et que tous les produits sont indisponibles
          let is_required = sidedish.isRequired(),
            products,
            products_size,
            stockout_count = 0;
          if (is_required) {
            // on vérifie que le cross-selling est obligatoire
            products = sidedish.getCategoryProducts();
            products_size = products?.size() || 0;
            if (products_size > 0) {
              // et qu'il a *dans le contexte* des produits
              // si il n'y en a pas, ça peut être lié à une distinction VAE/VAD
              products.each((p) => {
                stockout_count +=
                  !p.isBuyableInContext() && p.isStockout(true) ? 1 : 0;
              });
              isStockout = products.size() === stockout_count;
            }
          }
          // ou si un produit immuable est en rupture de stockout
          // if(!isStockout){
          //     id_product_immutable = sidedish.get('id_product_immutable');
          //     _(id_product_immutable).each(function(id_product){
          //         var p = app.getProduct(id_product);
          //         isStockout = isStockout || (!!p && !p.isBuyableInContext() && p.isStockout());
          //     })
          // }
        }
      });
    }
    return isStockout;
  }
  isStockoutNow() {
    // rupture de stock à chaud
    const product = this,
      cartProduct = app.getCart().getProduct(product.id + ""),
      isStockout = product.isStockout();
    return (
      isStockout ||
      (!!cartProduct && cartProduct.get("available_msg") === "STOCKOUT")
    );
  }
  allowPreorder() {
    return (this.get("preorder_type") || 0) > 0;
  }
  getPreorderButtonLabel() {
    var cart = defaultCart(),
      type = this.isBuyableInContextType(cart),
      label,
      translationContext = TRANSLTATION_PRODUCT_CONTEXT;
    switch (type) {
      case 5: // switch VAD to VAE
        label = cart.isVAE() ? "En livraison" : "À emporter";
        translationContext = "Cart";
        break;
      default:
        label = "Précommander";
    }
    return app.t(label, translationContext);
  }
  // hasNutritionalData(): boolean {
  //   const n = this.get("nutritional_data");
  //   return !!(n && n.line && n.line.length && n.col && n.col.length);
  //
  //   // if (!n || !n.line.length || !n.col.length) return false;
  //   // return true;
  // }
  // getNutritionalData() {
  //   var n = this.get("nutritional_data");
  //
  //   if (!n) n = { col: [], line: [] };
  //   return n;
  // }
  // getNutritionalDataCol() {
  //   return _(this.getNutritionalData().col);
  // }
  // getNutritionalDataLine() {
  //   return _(
  //     _(this.getNutritionalData().line).map(function (line) {
  //       return {
  //         name: line.name,
  //         col: _(line.col),
  //       };
  //     })
  //   );
  // }
  isAvailable() {
    return true;
  }
  isBuyableInContext(cart?: CartModel, preventRecursion?: boolean) {
    return this.isBuyableInContextType(cart, preventRecursion) === 0;
  }
  isBuyableInContextType(cart?: CartModel, preventRecursion?: boolean) {
    const product = this;
    cart = defaultCart(cart);
    let visibility = product.get("visibility");

    if (product.get("deleted")) return 1;

    //
    // actif sur le web
    //
    if (!product.isActive()) {
      return 1;
    }
    //
    // disponibilité par boutique
    //
    if (!product.isActiveInStore(cart)) {
      return 2;
    }

    //
    // disponibilité par période [from,to]
    //
    if (!product.isActiveFromTo(cart)) {
      return 3;
    }

    //
    // disponibilité par créneau horaire et jour
    // valeurs dispobibles :
    //      never | always | {from,to,active} | [{from,to,active}...]
    //
    if (!isCartMomentVisible(visibility, true, cart)) {
      return 4;
    }

    // si le mode de livraison n'est pas permis
    if (!product.allowDeliveryMode(cart)) {
      return 5;
    }

    // si le produit est en rupture de stock
    if (product.isStockout(preventRecursion)) {
      return 6;
    }

    return 0;
  }
  isActiveFromTo(cart: CartModel, onlyVisible?: boolean) {
    //
    // disponibilité par période [from,to]
    //
    cart = defaultCart(cart);
    const product = this,
      cartMoment = cart.getDatetimeMoment();
    let from = product.get("from"),
      to = product.get("to");

    from = from ? from.replace(/-/g, "/") : false;
    to = to ? to.replace(/-/g, "/") : false;
    if (onlyVisible === true && product.get("display_outdated")) {
      // vérifie seulement la visibilité
      return true;
    }
    if (from) {
      if (!cartMoment.isAfter(new Date(from))) {
        return false;
      }
    }
    if (to) {
      if (!cartMoment.isBefore(new Date(to))) {
        return false;
      }
    }
    return true;
  }
  isActiveInStore(cart: CartModel) {
    //
    // disponibilité par boutique
    //
    cart = defaultCart(cart);

    const product = this,
      store = cart.getStore(),
      cartValidate = cart.validateDeliveryOptions();
    let productStore,
      isValid = true;
    if (store && store.hasModifiers() && cartValidate) {
      // product
      productStore = store.getProduct(product);
      // si on a une boutique et qu'on a pas le produit,
      // il est implicitement concidéré comme désactivé du contexte
      if (!productStore) {
        isValid = false;
      }
    }

    // TODO: Hooks productCanBeSee
    /* hooks.execHook(
      "productCanBeSee",
      { product: this, store: store }
      function (val: any) {
        isValid = false;
      }
    ); */

    // Hook replacement
    if (!store && this.get("print_if_no_store") === false) {
      isValid = false;
    }

    return isValid;
  }

  getCustomerGroup() {
    return this.get("customer_group");
  }
  isActive() {
    return !!this.get("active_web");
  }

  getVisibility(): IVisibilityItem[] {
    const visibility = this.get("visibility") || "always";
    const isString = typeof visibility === "string";
    const isArray = visibility instanceof Array;
    let arrayVisibility: IVisibilityItem[] = [];
    const visibilityStringToObject = (str: string, day?: number) => {
      const isAlways = visibility === "always";
      const from = isAlways ? "00:00" : "01:00";
      const to = isAlways ? "23:59" : "01:00";
      return {
        day,
        from,
        to,
      } as IVisibilityItem;
    };
    if (isString || !isArray) {
      arrayVisibility = [0, 1, 2, 3, 4, 5, 6].map((day) =>
        isString
          ? visibilityStringToObject(visibility, day)
          : {
              day,
              ...(visibility as IVisibilityItem),
            }
      );
    } else if (visibility instanceof Array) {
      arrayVisibility = visibility.map((item) => {
        return typeof item === "string" ? visibilityStringToObject(item) : item;
      });
    }
    return arrayVisibility;
  }
  isVisibleInContext(withFilters?: boolean, cart?: CartModel) {
    cart = defaultCart(cart);
    //
    // l'attribut withFilters, permet d'utiliser le filtre par tags et allergenGroups
    //

    const product = this,
      visibility = product.get("visibility"),
      // si le produit est disponible en prévente, on considère que ça permet de l'afficher
      allowPreorderMode = product.allowPreorderMode(cart);
    //
    // actif sur le web
    //
    if (!product.isActive()) {
      return false;
    }
    //
    // disponibilité par boutique
    //
    const productGroup = product.getCustomerGroup();

    if (
      productGroup &&
      _(productGroup).intersection(app.getCustomer().getCustomerGroup())
        .length === 0
    ) {
      return false;
    }
    if (!product.isActiveInStore(cart)) {
      return false;
    }

    // disponibilité par période [from,to]
    //
    if (!product.isActiveFromTo(cart, true)) {
      return false;
    }

    if (!product.allowDeliveryMode(cart) && !allowPreorderMode) {
      return false;
    }
    //
    // disponibilité par créneau horaire et jour
    // valeurs dispobibles :
    //      never | always | {from,to,active} | [{from,to,active}...]
    //
    if (!isCartMomentVisible(visibility, false, cart) && !allowPreorderMode) {
      return false;
    }

    if (withFilters) {
      return product.isFiltred(cart);
    }
    return true;
  }

  allowPreorderMode(cart: CartModel) {
    cart = defaultCart(cart);
    var product = this,
      preorder_type = product.get("preorder_type") || 0,
      allowPreorder = preorder_type > 0,
      allow = allowPreorder;
    //    0 => 'ne pas précommander',
    //    1 => 'précommander',
    //    2 => 'précommander en VAE',
    //    3 => 'précommander en VAD',
    if (allowPreorder) {
      if (!product.allowDeliveryMode(cart)) {
        // si le produit est précommandable MAIS
        // n'est pas actif dans le mode de livraison,
        // on vérifie qu'on doit permettre la bascule VAE/VAD
        if (cart.isVAD() && preorder_type !== 2) {
          allow = false;
        }
        if (cart.isVAE() && preorder_type !== 3) {
          allow = false;
        }
      }
    }
    return allow;
  }
  allowDeliveryMode(cart: CartModel) {
    if (cart.isVAD() && !this.isActiveVad()) {
      return false;
    }
    if (!cart.isVAD() && !this.isActiveVae()) {
      return false;
    }
    return true;
  }
  isFiltred(cart: any) {
    // renvoie true, si le produit est visible dans la selection de filtre (tag et allergen)

    var product = this,
      productTags = product.getTags(),
      productAllergenGroups = product.getAllergenGroups(),
      validateStockout = true,
      validateTag =
        productTags.size() === 0 || !app.getTags().isFilterChecked(),
      validateAllergenGroup =
        productAllergenGroups.size() === 0 ||
        !app.getAllergenGroups().isFilterChecked();

    // si des filtres tags ont été selectionnés
    if (!validateTag) {
      // et si le produit a des tags et q'un des tags est selectionné
      validateTag = false;
      productTags.each(function (tag: { isChecked: () => any }) {
        validateTag = validateTag || tag.isChecked();
      });
    }
    // si des filtres d'allergene ont été déselectionnés
    if (!validateAllergenGroup) {
      validateAllergenGroup = true;
      // et si le produit a des allergen et qu'aucun n'est selectionné
      productAllergenGroups.each(function (allergenGroup: {
        isChecked: () => any;
      }) {
        validateAllergenGroup =
          validateAllergenGroup && allergenGroup.isChecked();
      });
    }

    return validateTag && validateAllergenGroup && validateStockout;
  }
  hasContextualData() {
    // est-ce que le produit a des données contextuelles (priceByShop, etc.)
    var hasContextualData = false;
    // TODO: hooks
    /* hooks.execHook("productHasContextualData", this, function () {
      hasContextualData = true;
    }); */
    return hasContextualData;
  }
}

ProductModel.prototype.backbonePattern = "Model";
ProductModel.prototype.name = "ProductModel";
ProductModel.prototype.idAttribute = "id_product";
ProductModel.prototype.route = "apiProductModel";
ProductModel.prototype.url = function () {
  return urlWithSyncVersion(`/product/${this.id}`);
};

function isCartMomentVisible(
  fromTo: IProductVisibility,
  buyable: boolean,
  cart: CartModel
): boolean {
  cart = defaultCart(cart);
  // var cartValidate = cart.validateDeliveryOptions(),
  let cartMoment = cart.getDatetimeMoment(),
    cartMomentDay = cartMoment.format("YYYY/MM/DD "),
    isVisible = false;
  // se base sur les modifier du store, et sur le contexte horaire panier
  if (fromTo === "always") {
    // si on pas de panier validé (donc pas d'horaire)
    // ou
    // si le produit est toujours actif
    isVisible = true;
  } else if (fromTo === "never") {
    // si on pas de panier validé (donc pas d'horaire)
    // ou
    // si le produit est toujours actif
    isVisible = false;
  } else if (_.isArray(fromTo)) {
    // on utilise l’index du jour du contexte 1 = lundi | 0 = dimanche (c'est faux!)
    // TODO attention l’API est lundi = 0
    isVisible = isCartMomentVisible(
      fromTo[(cartMoment.day() + 7 - 1) % 7],
      buyable,
      cart
    );
  } else if (fromTo && fromTo.from && fromTo.to) {
    // si le produit est dans le créneau horaire panier
    //    isVisible = true;
    isVisible =
      cartMoment.isAfter(new Date(cartMomentDay + fromTo.from)) &&
      cartMoment.isBefore(new Date(cartMomentDay + fromTo.to));
    if (!buyable) {
      // l'option buyable permet d'afficher même des produits non achetable
      isVisible = isVisible || Boolean(fromTo.display_message);
    }
  } else {
    // par défault, ce cas ne devrait pas arriver
    isVisible = false;
  }
  return isVisible;
}

export default ProductModel;
