import { startTransition, useEffect, useState } from "react";
import hasWindow from "@src/utils/hasWindow";
import isTouchDevice from "@src/utils/isTouchDevice";

const useLoadAfterInteraction = () => {
  // global key to store first interaction
  const firstInteractionKey = "__firstInteraction";
  const defaultState: boolean =
    // @ts-ignore
    (hasWindow() && window[firstInteractionKey]) || false;
  const [initialLoad, setInitialLoad] = useState(defaultState);

  useEffect(() => {
    if (defaultState || initialLoad) return;
    const isTouch = isTouchDevice();
    const stopListening = () => {
      window.removeEventListener("scroll", handle);
      !isTouch && window.removeEventListener("mousemove", handle);
      isTouch && window.removeEventListener("touchstart", handle);
    };
    const handle = (e: Event) => {
      if (!initialLoad) {
        stopListening();
        startTransition(() => {
          setTimeout(
            () => {
              // @ts-ignore
              window[firstInteractionKey] = true;
              setInitialLoad(true);
            },
            isTouch ? 800 : 0
          );
        });
      }
    };
    if (!initialLoad) {
      window.addEventListener("scroll", handle);
      !isTouch && window.addEventListener("mousemove", handle);
      isTouch && window.addEventListener("touchstart", handle);
    }
    return () => {
      stopListening();
    };
  }, [initialLoad]);

  return initialLoad;
};

export default useLoadAfterInteraction;
