import { graphql, useStaticQuery } from "gatsby";
import usePageLang from "../usePageLang";

export type ProductItem = Queries.productFieldsFragment;

export type UseAllProductNode = Queries.productFieldsFragment;

export const useAllProduct = (): {
  data: ProductItem[];
} => {
  const currentLang = usePageLang();
  // static query
  const { allProduct } = useStaticQuery<Queries.AllProductQuery>(graphql`
    query AllProduct {
      allProduct {
        nodes {
          ...productFields
        }
      }
    }
  `);

  let data: ProductItem[] = [];

  if (allProduct) {
    data = allProduct.nodes.filter(
      ({ lang }) => lang === currentLang
    ) as ProductItem[];
  }

  return { data };
};

export const getProduct = (
  data: ProductItem[],
  id_product?: string | number | null
) => {
  return data.find((product) => product.id_product === id_product) as
    | ProductItem
    | undefined;
};

export const getProductList = (
  data: ProductItem[],
  category: Queries.categoryFieldsFragment
) =>
  (category.products || []).map((id_product) => getProduct(data, id_product));
