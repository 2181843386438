import { Script } from "gatsby";
import React, { useEffect } from "react";
import { GATSBY_SOURCE_SUSHISHOP_BASE_URL } from "@src/utils/constants";
import { size } from "design-system/src/Components/Grid/_utils/size";
import isTouchDevice from "@src/utils/isTouchDevice";

type TagCoLoaderProps = {
  pageTemplate: string;
};

const USE_TAG_CO = !(process.env.GATSBY_IGNORE_TAG_CO === "1");
const tc_header_src = process.env.GATSBY_CONF_TAGCOMMANDERURLHEADER || "";
const tc_footer_src = process.env.GATSBY_CONF_TAGCOMMANDERURLFOOTER || "";
const tc_vars_src = `${GATSBY_SOURCE_SUSHISHOP_BASE_URL}/FRONTOFFICE/ajax/tc_vars.js.php`;

export const getEnvChannel = () => {
  // t = tablet
  // d = desktop
  // m = mobile
  let env_channel;
  try {
    const w = window.innerWidth,
      { md } = size;
    env_channel = w > md ? (isTouchDevice() ? "t" : "d") : "m";
  } catch (e) {
    env_channel = "e"; // error
  }
  return env_channel;
};
const TagCoLoader = ({ pageTemplate }: TagCoLoaderProps) => {
  const tc_vars_page = pageTemplate.toLowerCase();
  const [TcHeaderTag, setTcHeaderTag] = React.useState<JSX.Element | null>(
    null
  );
  const [TcVarsTag, setTcVarsTag] = React.useState<JSX.Element | null>(null);
  const [TcFooterTag, setTcFooterTag] = React.useState<JSX.Element | null>(
    null
  );
  const onLoadTcVars = () => {
    setTcHeaderTag(
      <Script
        key="tc_header"
        id={"tc_header"}
        src={tc_header_src}
        onLoad={onLoadHeader}
        strategy={"post-hydrate"}
      />
    );
  };

  const onLoadHeader = () => {
    setTcFooterTag(
      (prevEl) =>
        prevEl || (
          <Script
            id={"tc_footer"}
            src={tc_footer_src}
            async={true}
            defer={true}
          />
        )
    );
  };

  useEffect(() => {
    const tc_vars =
      tc_vars_src + "?page=" + tc_vars_page + "&formatview=" + getEnvChannel();
    setTcVarsTag(
      (prevEl) =>
        prevEl || (
          <Script
            key="tc_vars"
            id={"tc_vars"}
            src={tc_vars}
            onLoad={onLoadTcVars}
          />
        )
    );
  }, []);

  return (
    <>
      <style
        id="tgco-override"
        dangerouslySetInnerHTML={{
          __html: `
              .tc-privacy-wrapper {
                background: transparent;
                width: 100%;
                position: fixed;
                z-index: 999998;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 0;
                padding: 0 10px;
              }
              #popin_tc_privacy {
                position: relative;
                left: unset !important;
                top: unset !important;
              }
              `,
        }}
      ></style>
      {USE_TAG_CO && (
        <>
          {TcHeaderTag}
          {TcVarsTag}
          {TcFooterTag}
        </>
      )}
    </>
  );
};

export default TagCoLoader;
