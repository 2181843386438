import React, {
  useState,
  useEffect,
  CSSProperties,
  InputHTMLAttributes,
  FormEvent,
  useRef,
  useId,
  useImperativeHandle,
  FocusEventHandler,
  ReactNode,
} from "react";
import Icon from "../../Foundation/Icon/Icon";
import Button from "../../Foundation/Button/Button";
import Spinner from "design-system/src/Foundation/Spinner/Spinner";
import { IconName } from "../../lib/svgIcon/components";
import { _InputRoot, _InputHtml, _InputWrapper } from "./Input.styled";
import clsx from "clsx";
import { useTheme } from "../../Themes/defaultTheme";
import { IButtonProps } from "../../Foundation/Button/Button";
import useAutoResizeTextArea from "./useAutoResizeTextArea";
import Typography from "../../Foundation/Typography/Typography";
import SpanButton from "../../General/Reset/SpanButton/SpanButton";

type InputAttributes = InputHTMLAttributes<HTMLInputElement>;

export enum EInputState {
  DEFAULT = "default",
  ERROR = "error",
  SUCCESS = "success",
}

export enum EInputVariant {
  OUTLINE = "outline",
  FILL = "fill",
  DEFAULT = OUTLINE,
}

export enum EInputType {
  TEXT = "text",
  PASSWORD = "password",
  NUMBER = "number",
  DATE = "date",
  FILE = "file",
  EMAIL = "email",
}
export interface InputProps
  extends Omit<InputAttributes, "placeholder" | "type"> {
  icon?: IconName | (() => React.ReactNode);
  label?: string;
  type?: EInputType;
  value?: string;
  action?: "clear" | "submit";
  rootAs?: "div" | "span" | "form";
  state?: EInputState;
  variant?: EInputVariant;
  message?: string | ReactNode;
  actionLabel?: string;
  loading?: boolean;
  onAction?: () => void;
  disabled?: boolean;
  inputStyle?: CSSProperties;
  multiline?: boolean;
  rootClassName?: string;
  actionProps?: Omit<IButtonProps, "onClick" | "loading" | "type">;
  renderInput?: (props: InputProps) => React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    icon,
    className,
    label,
    style,
    type = EInputType.TEXT,
    action,
    actionLabel = "Ok",
    onAction,
    onChange,
    loading,
    disabled,
    inputStyle,
    rootAs = "div",
    variant = EInputVariant.DEFAULT,
    state = EInputState.DEFAULT,
    message,
    multiline,
    rootClassName,
    multiple,
    actionProps,
    ...inputProps
  } = props;
  const [inputType, setInputType] = useState(type);
  const { value, defaultValue } = inputProps;
  const [currentValue, setCurrentValue] = useState(value || defaultValue || "");
  const [hasMoreLines, setHasMoreLines] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const [filename, setFilename] = useState<string>("");
  const { color } = useTheme();
  const id = useId();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onFocus: FocusEventHandler<HTMLInputElement> = (e) => {
    setHasFocus(true);
    if (inputProps.onFocus) {
      inputProps.onFocus(e);
    }
  };

  const onBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    setHasFocus(false);
    if (inputProps.onBlur) {
      inputProps.onBlur(e);
    }
  };

  useAutoResizeTextArea(
    multiline ? (inputRef.current as unknown as HTMLTextAreaElement) : null,
    currentValue + ""
  );

  // all the functions or values you can expose here
  useImperativeHandle(ref, () => inputRef.current || ({} as HTMLInputElement));

  // -------------- handlers --------------------------------
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (type === EInputType.FILE) {
      if (e.target.files && e.target.files?.length > 0) {
        setFilename(e.target.files[0].name);
      } else {
        setFilename("");
      }
    } else {
      setCurrentValue(e.target.value);
    }

    onChange && onChange(e);
  };

  const handleClear = () => {
    if (!disabled) {
      setCurrentValue("");
    }
    onAction && onAction();
  };

  const handleTogglePassword = () => {
    setInputType(
      inputType === EInputType.TEXT ? EInputType.PASSWORD : EInputType.TEXT
    );
  };

  // -------------- lifecycle -------------------------------
  useEffect(() => {
    setCurrentValue((prevValue) => {
      if (value !== undefined && prevValue !== value) {
        return value;
      }
      if (defaultValue !== undefined && prevValue !== defaultValue) {
        return defaultValue;
      }
      return prevValue;
    });
  }, [value, defaultValue]);

  return (
    <_InputWrapper>
      <_InputRoot
        {...(rootAs === "form"
          ? {
              as: rootAs,
              method: "POST",
              onSubmit: (e: FormEvent) => {
                console.log("OnSubmit");
                e.preventDefault();
                e.stopPropagation();
                onAction && onAction();
                inputRef.current?.blur();
              },
            }
          : { as: "div" })}
        $type={type}
        $state={state}
        $variant={variant}
        $hasFocus={hasFocus}
        style={action === "submit" ? { ...style, paddingRight: 3 } : style}
        className={clsx(
          {
            multiline,
            "has-more-lines": hasMoreLines,
          },
          rootClassName
        )}
        ref={containerRef}
      >
        {type === EInputType.FILE ? (
          <Icon
            size={24}
            type="svg"
            icon={"download"}
            color={color.greyText2}
          />
        ) : typeof icon === "function" ? (
          icon()
        ) : (
          icon && (
            <Icon
              size={24}
              className={icon}
              type="svg"
              icon={icon}
              color={color.greyText2}
            />
          )
        )}

        <_InputHtml
          as={multiline ? "textarea" : "input"}
          placeholder={label}
          type={inputType}
          className={clsx(className, "input", { multiline })}
          onChange={handleChange}
          disabled={disabled}
          style={{
            ...inputStyle,
            ...(multiline
              ? { width: "100%", overflow: "hidden", resize: "none" }
              : {}),
          }}
          id={id.toString()}
          ref={inputRef}
          aria-label={label}
          {...inputProps}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        {type === EInputType.FILE && (
          <label htmlFor={id.toString()}>{filename || label}</label>
        )}
        {type === EInputType.PASSWORD && (
          <SpanButton className={"pwd-btn-eye"} onClick={handleTogglePassword}>
            <Icon
              icon={inputType === type ? "eye" : "eye_off"}
              color={color.greyText2}
            />
          </SpanButton>
        )}
        {currentValue && action === "clear" ? (
          <span
            onClick={handleClear}
            style={{ cursor: "pointer", display: "inline-flex" }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <Icon size={24} type="svg" icon={"trash"} color="#0E0D0F" />
            )}
          </span>
        ) : (
          currentValue &&
          action === "submit" && (
            <Button
              onClick={onAction}
              loading={loading}
              type={"button"}
              {...actionProps}
            >
              {actionLabel}
            </Button>
          )
        )}
      </_InputRoot>
      <>
        {message ? (
          typeof message === "string" ? (
            <Typography
              variant="ui4"
              color={state === EInputState.ERROR ? "error" : "greyText"}
            >
              {message}
            </Typography>
          ) : (
            message
          )
        ) : null}
      </>
    </_InputWrapper>
  );
});

export default Input;
