import {
  PageProps,
  WrapPageElementBrowserArgs,
  WrapPageElementNodeArgs,
} from "gatsby";
import React, { useEffect, useMemo, useState, useRef, Suspense } from "react";
import EmptyLayout from "./layout/EmptyLayout/EmptyLayout";
import ModalRoutingContext from "../plugins/layout-wrapper/ModalRoutingContext";
import { useRollingStartNavigation } from "./lib/rolling-start-navigation";
import { IWithDrawerProps } from "design-system/src/HOC/withDrawer";
import { useAllProduct } from "@src/hooks/query/useAllProduct";
import "./layout/shared/Critical.styles.min.css";
import LayoutContext from "./LayoutProvider.context";
import Modal from "./components/feedback/Modal/Modal";
// import DefaultLayout from "./layout/DefaultLayout/DefaultLayout";
//import CategoryLayout from "./layout/CategoryLayout/CategoryLayout";
// import InternationalLayout from "./layout/InternationalLayout/InternationalLayout";
import Spinner from "design-system/src/Foundation/Spinner/Spinner";
import TagCoLoader from "@src/components/general/WrapPageElement/TagCoLoader";
import { PageSlice } from "@src/store/reducers/PageSlice";
import { useDispatch } from "react-redux";
// import Home from "./components/page-content/Home/Home";
// const Home = () => (
//   <div style={{ minWidth: "50vw", minHeight: "50vh", background: "blue" }}>
//     Home Page default. Should be lazy loaded
//   </div>
// );
//React.lazy(() => import("./components/page-content/Home/Home"));
const Home = React.lazy(() => import("./components/page-content/Home/Home"));

const DefaultLayout = React.lazy(
  () => import("./layout/DefaultLayout/DefaultLayout")
);
const CategoryLayout = React.lazy(
  () => import("./layout/CategoryLayout/CategoryLayout")
);
const MyAccountLayout = React.lazy(
  () => import("./layout/MyAccountLayout/MyAccountLayout")
);
const StoreLocatorLayout = React.lazy(
  () => import("./layout/StoreLocatorLayout/StoreLocatorLayout")
);

const FragmentLayout = (
  props: any & { children?: React.ReactNode; key?: React.Key }
) => {
  return <React.Fragment key={props.key}>{props.children}</React.Fragment>;
};
export type ILayoutProviderProps = {
  element: WrapPageElementBrowserArgs["element"];
  props:
    | WrapPageElementBrowserArgs<
        any,
        any,
        { modal?: boolean } & Record<string, any>
      >["props"]
    | WrapPageElementNodeArgs<any>["props"];
  locationState?: WrapPageElementBrowserArgs<
    any,
    any,
    { modal?: boolean } & Record<string, any>
  >["props"]["location"]["state"];
};

export type InnerLayoutProps = {
  context: Record<string, unknown>;
  data?: Record<string, unknown>;
  params?: Record<string, unknown>;
  location?: PageProps["location"];
  isModal?: boolean;
};

// Layout list
const layouts = {
  DefaultLayout,
  EmptyLayout,
  CategoryLayout,
  MyAccountLayout,
  StoreLocatorLayout,
};

const getElementLayout = (
  element?: React.ReactElement<any, string | React.JSXElementConstructor<any>>
) => {
  if (!element) {
    return null;
  }
  return typeof element.type !== "string" &&
    // @ts-ignore
    element.type.Layout
    ? // @ts-ignore
      layouts[element.type.Layout] || DefaultLayout
    : DefaultLayout;
};

const getElementUri = (
  element?: React.ReactElement<any, string | React.JSXElementConstructor<any>>
) => {
  const uri: string = element?.props?.uri + "";
  if (!uri) {
    return "/";
  }
  const {
    pageContext: { language },
  } = element?.props || {};
  if (language) {
    const regex = new RegExp(`\/?${language}(\/.*)`);
    const tag = regex.exec(uri);

    if (tag && tag[1]) {
      // console.log("tag", tag[1]);
      return tag[1];
    }
  }
  return uri;
};

const LayoutProvider = (props: ILayoutProviderProps) => {
  const { element, props: wrapperProps } = props;
  // const [mounted, setMounted] = useState(false);
  const [shouldRedirectToRisingSun, setShouldRedirectToRisingSun] =
    useState(false);
  // Init product data
  useAllProduct();
  const { navigate, isRollingStart } = useRollingStartNavigation();
  const dispatch = useDispatch();
  const PreviousLayout = useRef<React.ComponentType<InnerLayoutProps>>();
  const PreviousNestedLayout = useRef<string[] | null>(null);
  const PreviousElement =
    useRef<
      React.ReactElement<any, string | React.JSXElementConstructor<any>>
    >();

  const { pageContext, data, location, params } = wrapperProps;
  const [viewportModalDiff, setViewportModalDiff] = useState<number>(0);
  const locationState: { modal?: boolean; closeTo?: string } = useMemo(
    () =>
      (location.state as WrapPageElementBrowserArgs<
        any,
        any,
        { modal?: boolean } & Record<string, any>
      >["props"]["location"]["state"]) || {},
    [location.state]
  );
  const isModal: boolean = useMemo(
    () =>
      // @ts-ignore
      !!(locationState && locationState.modal) ||
      // @ts-ignore
      element.type.isModal === true ||
      // @ts-ignore
      element.type.isDrawer,
    // @ts-ignore
    [element.type.isDrawer, element.type.isModal, locationState]
  );

  const closeTo: string = useMemo(
    () =>
      PreviousElement.current
        ? getElementUri(PreviousElement.current)
        : (locationState && locationState.closeTo) || "",
    [locationState]
  );

  // Drawer component
  const Drawer: React.ComponentType<IWithDrawerProps> | undefined = useMemo(
    () =>
      typeof element.type !== "string" &&
      // @ts-ignore
      element.type.Drawer
        ? // @ts-ignore
          element.type.Drawer
        : undefined,
    [element.type]
  );

  // ParentContent
  const ParentContent: React.ComponentType = useMemo(
    () =>
      typeof element.type !== "string" &&
      // @ts-ignore
      element.type.ParentContent
        ? // @ts-ignore
          element.type.ParentContent
        : Home,
    [element.type]
  );

  // layout props
  const layoutProps: InnerLayoutProps = useMemo(
    () => ({
      context: pageContext,
      data: data,
      location: location,
      params: params,
      isModal: isModal,
      // @ts-ignore
      wrapper: element.type.LayoutWrapper || undefined,
    }),
    [
      data,
      // @ts-ignore
      element.type.LayoutWrapper,
      isModal,
      location,
      pageContext,
      params,
    ]
  );

  /*  */
  const PageElement = useMemo(
    () =>
      isModal
        ? PreviousElement.current ||
          // @ts-ignore
          React.createElement(ParentContent, { ...layoutProps })
        : element,
    [ParentContent, element, isModal, layoutProps]
  );

  // Rendering the current page as a modal, so create an element with the page contents
  const modalElement = isModal ? element : null;

  const handleModalClose = () => {
    navigate(closeTo || "/");
  };

  // useEffect(() => {
  //   if (!mounted) {
  //     setMounted(true);
  //   }

  //   return () => {
  //     if (mounted) {
  //       setMounted(false);
  //     }
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const nestedLayout = useMemo(() => {
    // @ts-ignore
    const LayoutType = element.type.Layout as string;
    if (isModal) {
      return PreviousNestedLayout.current || ["EmptyLayout"];
    } else if (LayoutType === "DefaultLayout") {
      return ["EmptyLayout"];
    } else {
      return ["EmptyLayout", LayoutType];
    }
  }, [element, isModal]);

  useEffect(() => {
    const rollingStart = isRollingStart(location.pathname);
    if (
      !rollingStart &&
      window.location.port !== "8080" // dev mode should be full rolling start
    ) {
      console.info("Routing back to Rising Sun");
      setShouldRedirectToRisingSun(true);
      window.location.reload();
    }
    dispatch(PageSlice.actions.setIsModal(isModal));
    if (!isModal) {
      PreviousLayout.current = getElementLayout(element);
      PreviousNestedLayout.current = nestedLayout;
      PreviousElement.current = element;
    }
  }, [element, isModal]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const clientWidth = document.body.clientWidth;
      const innerWidth = window.innerWidth;
      const diff = innerWidth - clientWidth;
      if (diff > 0) {
        setViewportModalDiff(diff);
      }
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, [isModal]);

  // Layout component
  // const Layout: React.ComponentType<InnerLayoutProps> = useMemo(
  //   () =>
  //     !isModal
  //       ? getElementLayout(element)
  //       : PreviousLayout.current || getElementLayout(element) || EmptyLayout,
  //   [element, isModal]
  // );

  const MainLayout = useMemo(() => {
    return (
      <Suspense>
        {nestedLayout.reduceRight((acc, layout) => {
          // @ts-ignore
          const InnerLayout = layouts[layout] || FragmentLayout;
          return <InnerLayout {...layoutProps}>{acc}</InnerLayout>;
        }, PageElement)}
      </Suspense>
    );
  }, [PageElement, nestedLayout]);

  return shouldRedirectToRisingSun ? (
    <div
      style={{
        background: "#111",
        color: "#fff",
        width: "100vw",
        height: "100vh",
        position: "relative",
      }}
    >
      <Spinner absolute={true} />
    </div>
  ) : (
    <LayoutContext.Provider value={{ isModal }}>
      <div className="layout-root">
        {MainLayout}
        {isModal && (
          <Modal
            show={isModal}
            isPage={true}
            onClose={handleModalClose}
            // @ts-ignore
            {...element.modalProps}
          >
            <ModalRoutingContext.Provider
              value={{
                modal: true,
                closeTo: PreviousElement.current ? closeTo : null,
              }}
            >
              {!!Drawer ? (
                <Drawer open={true} onClose={handleModalClose} />
              ) : (
                modalElement
              )}
            </ModalRoutingContext.Provider>
          </Modal>
        )}
        {isModal && (
          <style
            id="body-override"
            dangerouslySetInnerHTML={{
              __html: `
                body{
                  overflow: hidden;  
                  width: calc(100% - ${viewportModalDiff}px);           
                }`,
            }}
          />
        )}

        {/*</SuspenseAfterSetup>*/}
        <TagCoLoader pageTemplate={pageContext.pageTemplate || ""} />
      </div>
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
